@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;
    --angle: 135deg;
  }
}

body {
  min-height: 100vh;
}

.PhoneInputCountry {
  padding-right: 0.6em;
  padding-left: 0.5rem;
  margin-right: 0 !important;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.PhoneInputCountry:hover {
  background-color: rgb(243, 244, 246);
}

.PhoneInputCountrySelect {
  cursor: default !important;
}

.PhoneInputInput {
  padding-left: 0.375rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  border-radius: 0;
}

.PhoneInputInput:focus-visible {
  outline: 2px solid transparent;
}

.prose > table:not([border="0"]) {
  border: 1px solid #d1d5db;
}

.prose > table:not([border="0"]) > tbody > tr > td {
  border: 1px solid #d1d5db;
}

.prose > hr {
  margin-top: 0;
  margin-bottom: 1rem;
}

.prose > p {
  margin-top: 0;
  margin-bottom: 10px;
}

.prose > ol {
  margin-top: 0;
  margin-bottom: 10px;
}

.landing-content > p > a {
  color: rgb(96, 165, 250);
}

.landing-content > p > a:visited {
  color: rgb(168, 85, 247);
}

@keyframes shimmer {
  5% {
    opacity: 0.5;
  }
  10% {
    opacity: 1;
  }
}

.animate-shimmer {
  animation: shimmer 4s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.animate-border {
  border: 4px solid #0000;
  border-radius: 12px;
  background: linear-gradient(#ffffff, #ffffff) padding-box,
    linear-gradient(var(--angle), #818cf8, #818cf8, #818cf8, #818cf8, #818cf8, #a5b4fc) border-box;
  animation: 4s rotate linear 100ms infinite;
}

.animate-notice-border-green {
  border: 1px solid #0000;
  background: linear-gradient(#4ade80, #4ade80) padding-box,
    linear-gradient(var(--angle), #059669, #059669, #059669, #059669, #059669, #34d399) border-box;
  animation: 4s rotate linear 100ms infinite;
}

.animate-notice-border-yellow {
  border: 1px solid #0000;
  background: linear-gradient(#fcd34d, #fcd34d) padding-box,
    linear-gradient(var(--angle), #ca8a04, #ca8a04, #ca8a04, #ca8a04, #ca8a04, #facc15) border-box;
  animation: 4s rotate linear 100ms infinite;
}

.animate-notice-border-red {
  border: 1px solid #0000;
  background: linear-gradient(#ef4444, #ef4444) padding-box,
    linear-gradient(var(--angle), #dc2626, #dc2626, #dc2626, #dc2626, #dc2626, #f87171) border-box;
  animation: 4s rotate linear 100ms infinite;
}

.animate-ticket-border {
  background: linear-gradient(var(--angle), #818cf8, #818cf8, #818cf8, #818cf8, #818cf8);
}

.animate-ticket-border-loop {
  background: linear-gradient(var(--angle), #818cf8, #818cf8, #818cf8, #818cf8, #818cf8);
  animation: 4s ticket-rotate linear 100ms infinite;
}

.animate-ticket-border:hover {
  animation: 6s ticket-rotate linear 100ms infinite;
}

@keyframes rotate {
  0% {
    --angle: -45deg;
  }
  5% {
    --angle: 95deg;
  }
  95% {
    --angle: 175deg;
  }
  100% {
    --angle: 315deg;
  }
}

@keyframes ticket-rotate {
  0% {
    --angle: 225deg;
    background: linear-gradient(var(--angle), #818cf8, #818cf8, #818cf8, #818cf8, #818cf8);
  }
  0.1% {
    --angle: 232deg;
    background: linear-gradient(var(--angle), #818cf8, #818cf8, #818cf8, #818cf8, #818cf8, #a5b4fc);
  }
  4.9% {
    --angle: 578deg;
    background: linear-gradient(var(--angle), #818cf8, #818cf8, #818cf8, #818cf8, #818cf8, #a5b4fc);
  }
  5% {
    --angle: 585deg;
    background: linear-gradient(var(--angle), #818cf8, #818cf8, #818cf8, #818cf8, #818cf8);
  }
  100% {
    --angle: 585deg;
    background: linear-gradient(var(--angle), #818cf8, #818cf8, #818cf8, #818cf8, #818cf8);
  }
}

.animate-marquee {
  -moz-transform: translate(0);
  -webkit-transform: translate(0);
  transform: translate(0);
  -moz-animation: scroll-left 10s linear infinite;
  -webkit-animation: scroll-left 10s linear infinite;
  animation: scroll-left 10s linear infinite;
}

@-moz-keyframes scroll-left {
  0% {
    -moz-transform: translateX(0);
  }
  100% {
    -moz-transform: translateX(calc(-100% - 16px));
  }
}

@-webkit-keyframes scroll-left {
  0% {
    -webkit-transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-100% - 16px));
  }
}

@keyframes scroll-left {
  0% {
    -moz-transform: translateX(0);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -moz-transform: translateX(calc(-100% - 16px));
    -webkit-transform: translateX(calc(-100% - 16px));
    transform: translateX(calc(-100% - 16px));
  }
}

.rdp-vhidden {
  @apply hidden;
}
